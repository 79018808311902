import store from '../../../Store';
import { hideGlobalLoaderAction, showGlobalLoaderAction } from '../Actions';
import { LoaderState } from '../Types';

export function showLoaderUseCase({ label }: LoaderState) {
  store.dispatch(showGlobalLoaderAction({ label }));
}

export function hideLoaderUseCase() {
  store.dispatch(hideGlobalLoaderAction());
}
