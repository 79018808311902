import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import LazyRoute from 'Common/Components/LazyRoute';
import { ROUTES } from './Constants/routes';
import Tools from './Modules/Tools';
import Logs from './Modules/Logs';

const LazyAcademy = lazy(() => import('./Modules/Academy'));
const LazyLogin = lazy(() => import('./Modules/Authorization/Containers/Login'));
const LazyLogout = lazy(() => import('./Modules/Authorization/Containers/Logout'));
const LazySetupPassword = lazy(() => import('./Modules/Authorization/Containers/SetupPassword'));
const LazyCompany = lazy(() => import('./Modules/Company'));
const LazyUser = lazy(() => import('./Modules/User'));
const LazyCompanySettings = lazy(() => import('./Modules/Settings/Containers/CompanySettings'));
const LazyUserProfile = lazy(() => import('./Modules/Settings/Containers/UserProfile'));
const LazyForgotPassword = lazy(() => import('./Modules/Authorization/Containers/ForgotPassword'));
const LazyVerifyOTP = lazy(() => import('./Modules/Authorization/Containers/VerifyPassword'));
const LazyChangePassword = lazy(() => import('./Modules/Authorization/Containers/ChangePassword'));
const LazySuccessChangePassword = lazy(
  () => import('./Modules/Authorization/Containers/SuccessPage'),
);
const LazyPartner = lazy(() => import('./Modules/Partners'));
const LazyDebug = lazy(() => import('./Modules/Debug'));

const authRoutes = [
  { path: ROUTES.ACADEMY, element: <LazyRoute lazy={LazyAcademy} /> },
  { path: ROUTES.COMPANY_MODULE, element: <LazyRoute lazy={LazyCompany} /> },
  { path: ROUTES.LOGOUT, element: <LazyRoute lazy={LazyLogout} /> },
  { path: ROUTES.USER_MODULE, element: <LazyRoute lazy={LazyUser} /> },
  { path: ROUTES.SETTINGS, element: <LazyRoute lazy={LazyCompanySettings} /> },
  { path: ROUTES.EMPLOYEE_PROFILE, element: <LazyRoute lazy={LazyUserProfile} /> },
  { path: ROUTES.BASE, element: <Navigate to={ROUTES.COMPANY} /> },
  { path: ROUTES.PARTNER_MODULE, element: <LazyRoute lazy={LazyPartner} /> },
  { path: ROUTES.DEBUG, element: <LazyRoute lazy={LazyDebug} /> },
  { path: ROUTES.TOOLS_MODULE, element: <Tools /> },
  { path: ROUTES.LOG_MANAGEMENT_MODULE, element: <Logs /> },
];

const unAuthRoutes = [
  { path: ROUTES.LOGIN, element: <LazyRoute lazy={LazyLogin} /> },
  { path: ROUTES.SETUP_PASSWORD, element: <LazyRoute lazy={LazySetupPassword} /> },
  { path: ROUTES.FORGOT_PASSWORD, element: <LazyRoute lazy={LazyForgotPassword} /> },
  { path: ROUTES.VERIFY, element: <LazyRoute lazy={LazyVerifyOTP} /> },
  { path: ROUTES.CHANGE_PASSWORD, element: <LazyRoute lazy={LazyChangePassword} /> },
  { path: ROUTES.SUCCESS, element: <LazyRoute lazy={LazySuccessChangePassword} /> },
];

export { authRoutes, unAuthRoutes };
