import store from '../../../Store';
import { AuthState } from '../Types';
import { clearAuthStateAction, setAuthStateAction } from './actions';

export const setAuthState = (authState: AuthState) => {
  store.dispatch(setAuthStateAction(authState));
};

export const clearAuthState = () => {
  store.dispatch(clearAuthStateAction());
};
