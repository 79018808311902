import React, {
  ComponentType, FC, FormEventHandler, useEffect, useRef,
} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { createPortal } from 'react-dom';
import Form from '../Form';
import { CustomComponentProps } from '../../Types/component';
import ModalTitle from './ModalTitle';
import ModalTitleHeading from './ModalTitleHeading';
import ModalContent from './ModalContent';
import ModalFooter from './ModalFooter';
import styles from './modal.module.scss';
import './styles.scss';

export type ModalComponent = 'div' | 'form';
type ModalSizes = 'lg' | 'md' | 'sm' | 'xs' | 'default';

interface ModalProps extends CustomComponentProps {
  show?: boolean;
  containerClassName?: string;
  overlayClassName?: string;
  type?: ModalComponent;
  size?: ModalSizes;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  onClose: (e: MouseEvent) => void;
  renderInBody?: boolean;
}

const getModalClassFromSize = (size?: ModalSizes) => `wo-modal-${size}`;

const modalStack: React.RefObject<HTMLDivElement>[] = [];

function Modal({
  show = false,
  children = null,
  containerClassName = '',
  overlayClassName = '',
  className = '',
  type = 'div',
  size = 'default',
  onSubmit = () => {},
  onClose,
  renderInBody = true,
}: ModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (show && modalRef.current) {
      modalStack.push(modalRef);
    }
    return () => {
      const index = modalStack.indexOf(modalRef);
      if (index > -1) modalStack.splice(index, 1);
    };
  }, [show]);

  const handleCloseModal = (e: MouseEvent) => {
    if (modalStack[modalStack.length - 1] !== modalRef) return;

    const targetElement = e.target as HTMLElement;
    if (targetElement && targetElement.id && targetElement.id.startsWith('react-select')) return;

    onClose?.(e);
  };

  const enableOutsideClickClose = typeof onClose === 'function';

  const renderContent = () => {
    const classes = `${show ? styles.modal : styles.modalClose} wo-modal ${getModalClassFromSize(
      size,
    )} ${className}`;

    switch (type) {
      case 'form':
        return (
          <Form className={classes} onSubmit={onSubmit}>
            {children}
          </Form>
        );
      default:
        return <div className={classes}>{children}</div>;
    }
  };

  if (!show) return null;

  const modalElement = (
    <div className={`${styles.modalContainer} ${containerClassName}`} ref={modalRef}>
      <div className={styles.modalWrapper}>
        <div className={`${styles.overlay} ${overlayClassName}`} />
        {enableOutsideClickClose ? (
          <OutsideClickHandler onOutsideClick={handleCloseModal}>
            {renderContent()}
          </OutsideClickHandler>
        ) : (
          renderContent()
        )}
      </div>
    </div>
  );

  return renderInBody ? createPortal(modalElement, document.body) : modalElement;
}

export {
  ModalTitle, ModalContent, ModalFooter, ModalTitleHeading,
};

export function withCreatePortal<P extends object>(Component: ComponentType<P>): FC<P> {
  return function PortalComponent(props) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return createPortal(<Component {...props} />, document.body);
  };
}

export default Modal;

Modal.defaultProps = {
  show: false,
  containerClassName: '',
  overlayClassName: '',
  type: 'div',
  size: 'default',
  onSubmit: () => {},
  renderInBody: true,
};
