export enum METHODS {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
}

async function request<T>({
  url,
  body,
  method = METHODS.GET,
  headers,
}: {
  url: string;
  method?: METHODS;
  body?: BodyInit;
  headers?: RequestInit['headers'];
}): Promise<T> {
  const response = await fetch(url, {
    method,
    body,
    headers,
  });

  const data = await response.json();

  return data as T;
}

export default request;
