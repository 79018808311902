import { ActivePlanState } from '../../Types/activePlan';
import {
  ActivePlanStateAction, CLEAR_ACTIVE_PLAN_STATE, SET_ACTIVE_PLAN_STATE,
} from './actions';

const initialState: ActivePlanState = {
  companyId: '',
  activePlanId: '',
  companyName: '',
};

const activePlanReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: ActivePlanStateAction,
): ActivePlanState => {
  switch (action.type) {
    case SET_ACTIVE_PLAN_STATE: {
      const { companyId, activePlanId, companyName } = action.payload;
      return {
        ...state,
        companyId,
        activePlanId,
        companyName,
      };
    }
    case CLEAR_ACTIVE_PLAN_STATE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default activePlanReducer;
