import React, { FC } from 'react';
import Loader from '../Loader';

interface LazyRouteProps {
  lazy: React.LazyExoticComponent<FC>;
  fallback?: React.ReactNode;
}

function LazyRoute({ lazy: Component, fallback }: LazyRouteProps) {
  return (
    <React.Suspense fallback={fallback}>
      <Component />
    </React.Suspense>
  );
}
LazyRoute.defaultProps = {
  fallback: <Loader show label="Loading Page Content..." />,
};
export default LazyRoute;
