import React, { CSSProperties } from 'react';

interface SvgIconProps {
  color?: CSSProperties['color'];
  size?: number | string;
}

function CloseIcon({ color, size }: SvgIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 15 14"
    >
      <path
        fill={color}
        d="M14.5 1.41L13.09 0 7.5 5.59 1.91 0 .5 1.41 6.09 7 .5 12.59 1.91 14 7.5 8.41 13.09 14l1.41-1.41L8.91 7l5.59-5.59z"
      />
    </svg>
  );
}

CloseIcon.defaultProps = {
  color: 'currentColor',
  size: 10,
};

export default CloseIcon;
