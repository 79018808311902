export interface HandlerEvent {
  key: string;
  validate: CallableFunction;
}

export enum ContactOption {
  ISSUE_BUG = 'Issue / bug',
  QUERY = 'Query',
  FEATURE_REQUEST = 'Feature request',
}

export enum PriortyOption {
  URGENT = 'Urgent',
  HIGH = 'High',
  NORMAL = 'Normal',
  LOW = 'Low',
}

export interface IRaiseTicketForm {
  contactFor?: string;
  subject: string;
  details: string;
  priority?: string;
  selectedCompanyId?: string;
  selectedCompanyName?: string;
  media?: string | Blob;
  username: string;
  userId: string;
  role: string;
}

export interface RaiseATicketResponse {
  message: string;
  status: number;
  id: string;
}
