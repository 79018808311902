import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import PageNotFound from 'Common/Pages/PageNotFound';
import LazyRoute from 'Common/Components/LazyRoute';
import { ROUTES } from '../../Constants/routes';
import DeviceManagementLoadingSkeleton from './Components/Devices/DeviceManagementLoadingSkeleton';

const LazyDevices = React.lazy(() => import('./Containers/Devices'));
const LazyModels = React.lazy(() => import('./Containers/ModelManagement/Models'));
const LazyLocations = React.lazy(() => import('./Containers/ModelManagement/Locations'));

function Tools() {
  return (
    <Routes>
      <Route
        path={ROUTES.DEVICE_MANAGEMENT}
        element={<LazyRoute fallback={<DeviceManagementLoadingSkeleton />} lazy={LazyDevices} />}
      />
      <Route path={ROUTES.MODEL_MANAGEMENT} element={<LazyRoute lazy={LazyModels} />} />
      <Route path={ROUTES.LOCATION} element={<LazyRoute lazy={LazyLocations} />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default Tools;
