import React, { CSSProperties } from 'react';

interface SvgIconProps {
  color?: CSSProperties['color'];
  width?: number | string;
  height?: number | string;
}

function ProfileIcon({ color, width, height }: SvgIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 16.2285C0 14.6372 0.632141 13.1111 1.75736 11.9859C2.88258 10.8607 4.4087 10.2285 6 10.2285C7.5913 10.2285 9.11742 10.8607 10.2426 11.9859C11.3679 13.1111 12 14.6372 12 16.2285H10.5C10.5 15.035 10.0259 13.8904 9.18198 13.0465C8.33807 12.2026 7.19347 11.7285 6 11.7285C4.80652 11.7285 3.66193 12.2026 2.81802 13.0465C1.97411 13.8904 1.5 15.035 1.5 16.2285H0ZM6 9.47851C3.51375 9.47851 1.5 7.46477 1.5 4.97852C1.5 2.49227 3.51375 0.478516 6 0.478516C8.48625 0.478516 10.5 2.49227 10.5 4.97852C10.5 7.46477 8.48625 9.47851 6 9.47851ZM6 7.97852C7.6575 7.97852 9 6.63601 9 4.97852C9 3.32102 7.6575 1.97852 6 1.97852C4.3425 1.97852 3 3.32102 3 4.97852C3 6.63601 4.3425 7.97852 6 7.97852Z"
        fill={color}
      />
    </svg>
  );
}

ProfileIcon.defaultProps = {
  width: 12,
  height: 17,
  color: 'currentColor',
};

export default ProfileIcon;
