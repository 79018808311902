import React, { CSSProperties } from 'react';

interface SvgIconProps {
  color?: CSSProperties['color'];
  height?: string | number;
  width?: string | number;
}

function CompanyIcon({ color, width, height }: SvgIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 7.44848V0.615234L13.75 5.11523V15.6152H0.25V5.11523L5.5 7.44848ZM7 3.14198V9.75698L1.75 7.42373V14.1152H12.25V6.00548L7 3.14273V3.14198Z"
        fill={color}
      />
    </svg>
  );
}

CompanyIcon.defaultProps = {
  color: 'currentColor',
  width: 14,
  height: 16,
};

export default CompanyIcon;
