import * as React from 'react';
import styles from './model-title-heading.module.scss';

export interface ModalTitleHeadingProps {
  title?: string;
  description?: string;
}

function ModalTitleHeading({ title, description }: ModalTitleHeadingProps) {
  return (
    <>
      <h5 className={`wo-modal-title-heading ${styles.title}`}>{title}</h5>
      {description ? (
        <p className={`wo-modal-title-heading ${styles.description}`}>{description}</p>
      ) : null}
    </>
  );
}

ModalTitleHeading.defaultProps = {
  title: '',
  description: '',
};

export default ModalTitleHeading;
