import Skeleton from 'Common/Skeleton';
import React from 'react';
import styles from './skeleton.module.scss';

function DeviceListingSkeleton() {
  return (
    <div className={styles.leftSkeleton}>
      <div className={styles.deviceListingSkeleton}>
        <Skeleton width="94%" height={110} className={styles.skeleton} count={6} />
      </div>
    </div>
  );
}

export default DeviceListingSkeleton;
