import React, { CSSProperties } from 'react';

interface ArrowDownIconProps {
  className?: string;
  color?: CSSProperties['color'];
  height?: string | number;
  width?: string | number;
}
function ArrowDownIcon({ className, color, height, width }: ArrowDownIconProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.825 0.158203L5 3.97487L1.175 0.158203L0 1.3332L5 6.3332L10 1.3332L8.825 0.158203Z"
        fill={color}
      />
    </svg>
  );
}

ArrowDownIcon.defaultProps = {
  className: '',
  color: 'currentColor',
  height: '7',
  width: '10',
};

export default ArrowDownIcon;
