import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import OutsideClickHandler from 'react-outside-click-handler';
import { useNavigate, Link } from 'react-router-dom';
import { createSelector } from 'reselect';
import CompanyIcon from 'Common/Components/Icons/CompanyIcon';
import LogoutIcon from 'Common/Components/Icons/LogoutIcon';
import Avatar from 'Common/Components/Avatar';
import ArrowDownIcon from 'Common/Components/Icons/ArrowDownIcon';
import ProfileIcon from 'Common/Components/Icons/ProfileIcon';
import Button from 'Common/Components/Button';
import COLORS, { getPrimaryColor } from 'Common/Helpers/colors';
import UserSecureIcon from 'Common/Components/Icons/UserSecureIcon';
import { authenticateViewAndAction } from 'Common/Helpers/authByAccessList';
import { ACCESS_SUPER_ADMIN } from 'Common/Constants/accesslistConstants';
import LogoutUseCase from '../../Modules/Authorization/UseCases/LogoutUseCase';
import { AppState } from '../../Store';
import './styles.scss';
import { ROUTES } from '../../Constants/routes';
import accessSuperAdminUseCase from '../../Modules/Authorization/UseCases/accessSuperAdminUseCase';

interface HeaderProps {
  className: string;
}

export function Header({ className }: HeaderProps) {
  const navigate = useNavigate();

  const selectAuth = (state: AppState) => state.auth;

  const selectUser = createSelector([selectAuth], (auth) => ({
    user: auth?.user,
    alertData: [],
    company: auth?.manifest?.company,
  }));

  const { user, alertData = [], company } = useSelector(selectUser);

  const {
    name: userName = '',
    email: userEmail = '',
    displayImage: userDisplayImage = '',
  } = user || {};

  const { name: companyName = '' } = company || {};

  const [dropdownAnimation, setDropdownAnimation] = useState('');
  const [arrowPosition, setArrowPosition] = useState('--down');
  // const [showDownload, setShowDownload] = useState(false);

  const dropDownRef = useRef<HTMLDivElement | null>(null);

  // const showDownloadModal = () => setShowDownload(true);
  // const closeDownloadModal = () => setShowDownload(false);

  const dropDownHandler = () => {
    setDropdownAnimation((prev) => (prev === '' ? 'animation' : ''));
    setArrowPosition((prev) => (prev === '--down' ? '--up' : '--down'));
  };

  const closeDropDown = () => {
    setDropdownAnimation('');
    setArrowPosition('--down');
  };

  const handleLogout = () => {
    LogoutUseCase({ navigate });
  };

  const accessSuperAdmin = useCallback(async () => {
    await accessSuperAdminUseCase();
  }, []);

  const renderAvatar = ({ size, ratio }: { size?: string; ratio?: number }) => (
    <Avatar
      name={userName}
      src={userDisplayImage}
      size={size}
      round="50%"
      color={getPrimaryColor()}
      textSizeRatio={ratio}
    />
  );

  useEffect(() => {
    if (dropDownRef.current) {
      closeDropDown();
    }
  }, [navigate]);

  return (
    <header
      className={`${className} navbar ${
        alertData?.length > 0 ? 'header-with-alert' : 'header'
      } justify-content-end`}
    >
      <div />
      <div className="right-section">
        {/* <Navbar /> */}
        <OutsideClickHandler
          onOutsideClick={() => {
            if (dropDownRef.current) {
              setDropdownAnimation('');
              setArrowPosition('--down');
            }
          }}
        >
          <ul className="nav-ul-link-right">
            {/* <li>
              <Button
                type="button"
                variant="text"
                className="nav-right-link-btn"
                onClick={showDownloadModal}
              >
                <span className="nav-right-icon">
                  <MobileIcon width={10} height={10} />
                  {' '}
                  Download App
                </span>
              </Button>
            </li> */}
            <li>
              <Button
                type="button"
                variant="text"
                className="btn-textIcon user-profile-btn"
                onClick={dropDownHandler}
              >
                <>
                  <div className="text-right mr-2">
                    <div className="drop-profile--thumbHeader mr-1">
                      {renderAvatar({ size: '30', ratio: 0 })}
                    </div>
                  </div>
                  <span className={`arrow-icon${arrowPosition}`}>
                    <ArrowDownIcon />
                  </span>
                </>
              </Button>
            </li>
          </ul>
          <div className={`header__dropdown-container ${dropdownAnimation}`} ref={dropDownRef}>
            <div className="drop-wrapper--profile drop-flex-wrapper">
              <div className="drop-profile--thumb">{renderAvatar({ size: '40' })}</div>
              <div className="drop-profile--content">
                <ul>
                  <li>{userName || '-'}</li>
                  <li>{userEmail || '-'}</li>
                </ul>
              </div>
            </div>
            <div className="drop-wrapper--list">
              <ul>
                <li>
                  <div className="dropdown-link">
                    <CompanyIcon />
                    {' '}
                    <span>{companyName || '-'}</span>
                  </div>
                </li>
                <li>
                  <Link className="dropdown-link" to={ROUTES.EMPLOYEE_PROFILE}>
                    <>
                      <ProfileIcon />
                      <span>Account Settings</span>
                    </>
                  </Link>
                </li>
                {authenticateViewAndAction(ACCESS_SUPER_ADMIN) && (
                  <li>
                    <Button
                      id="logout-btn"
                      variant="text"
                      className="dropdown-link-btn btn-invisible"
                      onClick={accessSuperAdmin}
                    >
                      <>
                        <UserSecureIcon color={COLORS.GRAY} />
                        <span>Access Super Admin</span>
                      </>
                    </Button>
                  </li>
                )}

                <li>
                  <Button
                    id="logout-btn"
                    variant="text"
                    className="dropdown-link-btn btn-invisible"
                    onClick={handleLogout}
                  >
                    <>
                      <LogoutIcon />
                      {' '}
                      <span>Logout</span>
                    </>
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
      {/* <DownloadModal
        show={showDownload}
        onClose={closeDownloadModal}
        userEmail={userEmail}
      /> */}
    </header>
  );
}

export default Header;
