import { NOTIFICATION_TYPES, addNotification } from 'Common/Components/Notification';
import { METHODS } from 'Common/Requests/request';

async function uploadMediaRequest({ attachment, taskId }: any) {
  const form = new FormData();
  form.append('attachment', attachment || '');
  try {
    if (process.env.REACT_APP_CLICK_UP_ATTACHMENT_TOKEN) {
      await fetch(`https://api.clickup.com/api/v2/task/${taskId}/attachment`, {
        method: METHODS.POST,
        headers: {
          Authorization: process.env.REACT_APP_CLICK_UP_ATTACHMENT_TOKEN || '',
        },
        body: form,
      });
    } else {
      addNotification('Need Authorization token', NOTIFICATION_TYPES.ERROR);
    }
  } catch (error) {
    addNotification('Something went wrong, Failed to upload attachment', NOTIFICATION_TYPES.ERROR);
  }
}

export default uploadMediaRequest;
