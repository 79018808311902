import { AuthState, AuthStateActionI } from '../Types';

export const SET_AUTH_STATE = 'SET_AUTH_STATE';
export const CLEAR_AUTH_STATE = 'CLEAR_AUTH_STATE';

export type SetAuthStateAction = AuthStateActionI<typeof SET_AUTH_STATE>;
export type ClearAuthStateAction = AuthStateActionI<typeof CLEAR_AUTH_STATE>;

export function setAuthStateAction(payload: AuthState) {
  return {
    type: SET_AUTH_STATE,
    payload,
  };
}

export function clearAuthStateAction() {
  return {
    type: CLEAR_AUTH_STATE,
    payload: undefined,
  };
}

export type AuthStateAction = SetAuthStateAction | ClearAuthStateAction;
