import * as React from 'react';

interface AddRoundedIconProps {
  size?: string | number;
  color?: string;
}
function AddRoundedIcon({ size, color }: AddRoundedIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={color} />
      <g clipPath="url(#clip0_50308_4812)">
        <path
          d="M17 12.7143H12.7143V17H11.2857V12.7143H7V11.2857H11.2857V7H12.7143V11.2857H17V12.7143Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_50308_4812">
          <rect width="10" height="10" fill="white" transform="translate(7 7)" />
        </clipPath>
      </defs>
    </svg>
  );
}

AddRoundedIcon.defaultProps = {
  size: '16',
  color: '#194DDE',
};

export default AddRoundedIcon;
