import { JSX, MouseEventHandler } from 'react';

export enum MenuType {
  LINK = 'link',
  BUTTON = 'button',
  PARENT_LINK = 'parentLink',
}

interface MenuItemBase {
  id: string;
  label: string;
  icon?: JSX.Element;
  key: string;
  target?: '_self' | '_blank' | '_parent' | '_top';
}

interface ParentMenuItem extends MenuItemBase {
  type: MenuType.PARENT_LINK;
  subMenuItems?: LinkMenuItem[];
  to: string;
}

interface LinkMenuItem extends MenuItemBase {
  type: MenuType.LINK;
  to: string;
}

interface ButtonMenuItem extends MenuItemBase {
  type: MenuType.BUTTON;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export type MenuItem = LinkMenuItem | ButtonMenuItem | ParentMenuItem;

export interface SidebarI {
  top: MenuItem[];
  bottom: MenuItem[];
}
