import {
  NextRouteState,
  SET_NEXT_ROUTE,
  SetNextRouteAction,
  UNSET_NEXT_ROUTE,
  UnsetNextRouteAction,
} from './Types';

const initialState: NextRouteState = {
  url: '',
};

const nextRouteReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: SetNextRouteAction | UnsetNextRouteAction,
): NextRouteState => {
  switch (action.type) {
    case SET_NEXT_ROUTE:
      return { ...state, url: action.payload };
    case UNSET_NEXT_ROUTE:
      return { ...state, ...initialState };
    default:
      return state;
  }
};

export default nextRouteReducer;
