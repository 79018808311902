import React, { FormEventHandler } from 'react';
import styles from './form.module.scss';
import { CustomComponentProps } from '../../Types/component';

interface FormProps extends CustomComponentProps {
  onSubmit?: FormEventHandler<HTMLFormElement>;
}

function Form({ className = '', children = null, onSubmit }: FormProps) {
  return (
    <form className={`${styles.form} ${className}`} onSubmit={onSubmit}>
      {children}
    </form>
  );
}

Form.defaultProps = {
  onSubmit: () => {},
};

export default Form;
