import { getDeviceInfo } from './deviceInfo';

function getCommonHeaders() {
  const { deviceId, deviceType, name, timeZone } = getDeviceInfo();
  return {
    'Content-Type': 'application/json',
    tz: timeZone,
    'device-id': deviceId,
    'device-type': deviceType,
    device: name,
  };
}

export default getCommonHeaders;
