export const ROUTES = {
  BASE: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  SETUP_PASSWORD: '/setup-password',
  FORGOT_PASSWORD: '/forgot',
  VERIFY: '/verify',
  CHANGE_PASSWORD: '/changepassword',
  COMPANY_MODULE: '/company/*',
  COMPANY: '/company',
  ACADEMY: '/academy',
  NOT_FOUND: '/not-found',
  EMPLOYEE_PROFILE: '/profile-details',
  SETTINGS: '/settings/company-details',
  USER_MODULE: '/user/*',
  USER: '/user',
  SUCCESS: '/success',
  DEVICE_MANAGEMENT: '/devices',
  DEVICE_MANAGEMENT_MODULE: '/devices/*',
  ADD: '/add',
  EDIT: '/edit',
  PARTNER: '/partner',
  PARTNER_MODULE: '/partner/*',
  LOG_MANAGEMENT: '/logs',
  LOG_MANAGEMENT_MODULE: '/logs/*',
  DEBUG: '/logs/debug-dashboard',
  POD_STATUS: '/pod-status',
  TOOLS: '/tools',
  TOOLS_MODULE: '/tools/*',
  MODEL_MANAGEMENT: '/models/list',
  MODEL_MANAGEMENT_MODULE: '/models/list/*',
  LOCATION: '/models/locations',
  LOCATION_MODULE: '/models/locations/*',
  VISUAL_DETECTION: 'https://visual-detection.wobot.ai/',
  VISUAL_REID: 'https://visual-reid.wobot.ai/',
  SYNTHETIC_DATA: 'https://generate.wobot.ai/',
  AUTO_ANNOTATOR: 'https://wopipe-annotate.wobot.ai/',
};

export const DEFAULT_ROUTE = ROUTES.COMPANY;

export const PAGE_META_TITLES = {
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  SETUP_PASSWORD: 'SetupPassword',
  FORGOT_PASSWORD: 'Forgot Password',
  CHANGE_PASSWORD: 'Change Password',
  COMPANY: 'Company',
  USER: 'User',
  SETTINGS: 'Company Details',
  EMPLOYEE_PROFILE: 'User Profile',
  VERIFY: 'Verify Email',
  SUCCESS: 'Success',
  DEVICE_MANAGEMENT: 'Devices',
  PARTNER: 'Partner',
  DEBUG: 'Debug Dashboard',
};

export const getPageTitle = (title: string) => `${title} | Wobot.ai`;
