import React, { useState } from 'react';
import WhatNewIcon from 'Common/Components/Icons/WhatNewIcon';
import './HelpSection.scss';
import KnowledgeIcon from 'Common/Components/Icons/KnowledgeIcon';
import SiteStatusIcon from 'Common/Components/Icons/SiteStatusIcon';
import RaiseTicketIcon from 'Common/Components/Icons/RaiseTicketIcon';
import Button from 'Common/Components/Button';
import RaiseATicket from '../RaiseATicket';

interface IGetHelpLinks {
  role?: string;
  showModal?: () => void;
}

const getHelpLinks = ({ showModal }: IGetHelpLinks) => {
  const helpLinks = {
    top: [
      {
        icon: <WhatNewIcon />,
        link: 'https://updates.wobot.ai/updates',
        linkLabel: 'What’s new',
      },
      {
        icon: <KnowledgeIcon />,
        link: 'https://help.wobot.ai/',
        linkLabel: 'Knowledgebase',
      },
      {
        icon: <SiteStatusIcon />,
        link: 'https://status.wobot.ai/',
        linkLabel: 'Site status',
      },
    ],
    bottom: [
      {
        icon: <RaiseTicketIcon />,
        btnLabel: 'Raise a support request',
        className: 'raise-btn-link',
        showRaiseATicketModal: showModal,
        showBtn: true,
      },
    ],
  };
  return helpLinks;
};

function HelpSection() {
  const [showTicketModal, setShowTicketModal] = useState<boolean>(false);
  const showModal = () => setShowTicketModal(true);
  const hideModal = () => setShowTicketModal(false);
  const helpLinks = getHelpLinks({ showModal });
  return (
    <div className="help-section">
      <ul className="help-section__list">
        {helpLinks.top.map((link) => (
          <li className="help-section__link" key={link.linkLabel}>
            <span>{link.icon}</span>
            <a href={link.link} target="_blank" rel="noreferrer">
              {link.linkLabel}
            </a>
          </li>
        ))}
        <div className="help-section__line" />
        {helpLinks.bottom.map((link) => (
          <li className="help-section__link" key={link.btnLabel}>
            <Button
              className={link.className}
              variant="transparent"
              onClick={link.showRaiseATicketModal}
            >
              <>
                <span className="mr-2">{link.icon}</span>
                {link.btnLabel}
              </>
            </Button>
          </li>
        ))}
      </ul>
      {showTicketModal && <RaiseATicket show={showTicketModal} onClose={hideModal} />}
    </div>
  );
}

export default HelpSection;
