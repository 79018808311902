export const SET_NEXT_ROUTE = 'SET_NEXT_ROUTE';
export const UNSET_NEXT_ROUTE = 'UNSET_NEXT_ROUTE';

export interface NextRouteState {
  url: string;
}

export interface SetNextRouteAction {
  type: typeof SET_NEXT_ROUTE;
  payload: NextRouteState['url'];
}

export interface UnsetNextRouteAction {
  type: typeof UNSET_NEXT_ROUTE;
}
