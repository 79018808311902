import apiCall from '../../../Helpers/apiCall';
import { GetUserAuthDataResponse } from '../Types';

async function GetUserData({ showLoader = true }: { showLoader?: boolean }) {
  return apiCall<GetUserAuthDataResponse>(
    {
      url: '/v1/manifest',
    },
    {
      showLoader,
      loaderLabel: 'Fetching user details',
      notifyErrorMessage: true,
    },
  );
}

export default GetUserData;
