/* eslint-disable no-nested-ternary */
import { StylesConfig, GroupBase } from 'react-select';
import { SelectOption } from './index';
import COLORS from '../../../Helpers/colors';

function getCustomStyles(
  iconExists: boolean,
  error?: boolean,
): StylesConfig<SelectOption, boolean, GroupBase<SelectOption>> {
  return {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (base) => ({ ...base, zIndex: 9999, overflow: 'hidden' }),
    option: (styles, { isDisabled, isSelected, isFocused }) => ({
      ...styles,
      fontSize: '14px !important',
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? COLORS.PRIMARY
          : isFocused
            ? COLORS.SELECT_OPTION_BG
            : undefined,
      color: isDisabled
        ? undefined
        : isSelected
          ? COLORS.WHITE
          : isFocused
            ? COLORS.TEXT_OTHER
            : COLORS.GRAY,
      wordBreak: 'break-word',
    }),
    input: (styles) => ({
      ...styles,
      fontSize: '14px !important',
      fontWeight: '500 !important',
      color: `${COLORS.GRAY} !important`,
    }),
    control: (styles, { isFocused }) => ({
      ...styles,
      fontSize: '14px !important',
      fontWeight: '400 !important',
      color: `${COLORS.GRAY} !important`,
      borderRadius: '6px',
      padding: 0,
      border: error ? `1px solid ${COLORS.RED_DANGER} !important` : '',
      height: '37px !important',
      minHeight: 'unset !important',
      boxShadow: error
        ? `0 0 0 1px ${COLORS.RED_DANGER}`
        : isFocused
          ? `0 0 0 1px ${COLORS.PRIMARY}`
          : 'none',
      '&:hover': {
        border: error
          ? `1px solid ${COLORS.RED_DANGER} !important`
          : `1px solid ${COLORS.PRIMARY} !important`,
      },
    }),
    container: (styles) => ({
      ...styles,
      borderRadius: '6px',
      border: error ? `1px solid ${COLORS.RED_DANGER} !important` : '',
      '&:hover': {
        border: error ? `1px solid ${COLORS.RED_DANGER} !important` : '',
      },
    }),
    placeholder: (styles) => ({ ...styles, fontSize: '14px !important' }),
    singleValue: (styles) => ({ ...styles, fontSize: '14px !important', color: COLORS.GRAY }),
    menuList: (styles) => ({
      ...styles,
      padding: 0,
      '::-webkit-scrollbar': {
        height: '100%',
        width: '5px',
        border: '1px solid transparent',
      },
      '::-webkit-scrollbar-track': {
        borderRadius: '50px',
        background: COLORS.SELECT_OPTION_BG,
      },
      '::-webkit-scrollbar-thumb': { borderRadius: '50px', background: COLORS.TEXT_TERTIARY },
    }),
    valueContainer: iconExists
      ? (provided: any) => ({
        ...provided,
        paddingLeft: '30px',
      })
      : undefined,
    groupHeading: () => ({
      padding: '8px 12px',
    }),
    group: () => ({
      padding: 0,
    }),
  };
}

export default getCustomStyles;
