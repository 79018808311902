import React, { ButtonHTMLAttributes, MouseEventHandler } from 'react';
import styles from './styles.module.scss';
import { CustomComponentProps } from '../../Types/component';
import Loader from '../Loader';

export interface ButtonProps extends CustomComponentProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled?: boolean;
  loading?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'text' | 'default' | 'danger' | 'transparent';
}

function Button({
  id,
  children,
  className,
  onClick,
  type,
  variant,
  disabled,
  loading,
}: ButtonProps) {
  return (
    <button
      id={id}
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`btn btn-${variant} ${styles.woBtn} ${className || ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
      {loading ? <Loader className={styles.loader} show /> : null}
    </button>
  );
}

Button.defaultProps = {
  onClick: () => {},
  type: 'button',
  disabled: false,
  loading: false,
  variant: 'primary',
};

export default Button;
