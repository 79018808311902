import {
  GlobalLoaderAction, HIDE_GLOBAL_LOADER, SHOW_GLOBAL_LOADER,
} from '../Types';

export const showGlobalLoaderAction = ({
  label,
}: GlobalLoaderAction['payload']): GlobalLoaderAction => ({
  type: SHOW_GLOBAL_LOADER,
  payload: { label },
});

export const hideGlobalLoaderAction = (): GlobalLoaderAction => ({
  type: HIDE_GLOBAL_LOADER,
  payload: {},
});
