import * as React from 'react';
import { useSelector } from 'react-redux';
import './style.scss';
import { createSelector } from 'reselect';
import { AppState } from '../../Store';

function GlobalLoader() {
  // const { show: isLoaderVisible, label: loaderLabel } = useSelector(
  //   ({ loader: { show, label } = {} }: { loader: LoaderState }) => ({ show, label }),
  // );

  const selectLoader = (state: AppState) => state.loader;

  const selectIsLoaderVisible = createSelector([selectLoader], (loader) => loader?.show);

  const selectLoaderLabel = createSelector([selectLoader], (loader) => loader?.label);

  const isLoaderVisible = useSelector(selectIsLoaderVisible);
  const loaderLabel = useSelector(selectLoaderLabel);

  if (!isLoaderVisible) {
    return null;
  }

  return (
    <div
      className="loader-body"
      style={{
        position: 'fixed',
        display: 'block',
        zIndex: 1060,
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
        opacity: '0.9',
        top: 0,
        left: 0,
        right: 0,
        textAlign: 'center',
        overflow: 'hidden',
      }}
    >
      <div className="page-loader-wrapper content">
        <div className="loader">
          <div className="loaderBar" />
        </div>
        <p className="loaderLabel mt-4 text-muted">{loaderLabel}</p>
      </div>
    </div>
  );
}

export default GlobalLoader;
