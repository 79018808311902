import * as React from 'react';
import styles from './loader.module.scss';
import './styles.scss';
import LoaderIcon from '../Icons/LoaderIcon';

interface LoaderProps {
  containerClassName?: string;
  className?: string;
  show: boolean;
  size?: 'lg' | 'md' | 'sm' | 'xs';
  label?: string;
  description?: string;
}

function Loader({
  containerClassName,
  className,
  show,
  size,
  label = 'Loading...',
  description = '',
}: LoaderProps): JSX.Element | null {
  if (!show) {
    return null;
  }

  return (
    <div className={`${styles.loaderContainer} ${containerClassName}`}>
      <div className={styles.loaderWrapper}>
        <div className={`${styles.loader} ${className} loader-${size}`}>
          <LoaderIcon />
        </div>
        {label ? <p className={styles.label}>{label}</p> : null}
        {description ? <p className={styles.description}>{description}</p> : null}
      </div>
    </div>
  );
}

Loader.defaultProps = {
  containerClassName: '',
  className: '',
  size: 'lg',
  label: '',
  description: '',
};

export default Loader;
