import {
  applyMiddleware, createStore, Store,
} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import Logger from 'Common/Helpers/logger';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import rootReducer from './Reducers';
import { staticSecretKey } from '../Helpers/deviceInfo';

const encryptor = encryptTransform({
  secretKey: staticSecretKey,
  onError(error: Error) {
    Logger('Error during encryption/decryption:', error);
  },
});

const persistConfig = {
  key: 'root',
  storage,
  transforms: [encryptor],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type AppState = ReturnType<typeof rootReducer> & PersistPartial;

const config = {
  blacklist: ['persist/PERSIST', 'persist/REHYDRATE'],
};
const middlewares = [createStateSyncMiddleware(config)];

const store: Store<AppState> = createStore(
  persistedReducer,
  applyMiddleware(...middlewares, thunk as ThunkMiddleware),
);

export const persistor = persistStore(store);
initMessageListener(store);

export default store;
