export const COMPANIES_VIEW = 'companiesView';
export const COMPANIES_ACCESS_ACCOUNT = 'companiesAccessAccount';
export const COMPANIES_EDIT = 'companiesEdit';
export const COMPANIES_REQUEST_PLAN_UPDATE = 'companiesRequestPlanUpdate';
export const COMPANIES_RESTRICTED_MODULES_VIEW = 'companiesViewRestrictedModules';
export const COMPANIES_RESTRICTED_MODULES_EDIT = 'companiesEditRestrictedModules';
export const DEVICE_MANAGEMENT_VIEW = 'deviceManagementView';
export const DEVICE_MANAGEMENT_UPDATE_DEVICE = 'deviceManagementUpdateDevice';
export const DEVICE_MANAGEMENT_GOTO_PORTAINER = 'deviceManagementGotoPortainer';
export const DEVICE_MANAGEMENT_VIEW_FETCH_LOGS = 'deviceManagementViewFetchLogs';
export const DEVICE_MANAGEMENT_RESYNC_DEVICE = 'deviceManagementReSyncDevice';
export const DEVICE_MANAGEMENT_UPDATE_RESTART_SERVICE = 'deviceManagementUpdateRestartService';
export const DEVICE_MANAGEMENT_DELETE_SERVICE = 'deviceManagementDeleteService';
export const DEVICE_MANAGEMENT_CHECK_FOR_UPDATE = 'deviceManagementCheckForUpdate';
export const DEVICE_MANAGEMENT_LOGOUT_FROM_DEVICE = 'deviceManagementLogoutFromDevice';
export const SETTINGS_VIEW = 'settingsView';
export const SETTINGS_MANAGE = 'settingsManage';
export const TEAM_VIEW = 'teamView';
export const TEAM_ADD_USER = 'teamAddUser';
export const TEAM_EDIT_DELETE_USER = 'teamEditDeleteUser';
export const TEAM_EDIT_INTERNAL_ADMIN = 'teamEditInternalAdmin';
export const COMPANIES_ADD_NEW = 'companiesAddNew';
export const PARTNERS_VIEW = 'partnersView';
export const PARTNERS_MANAGE = 'partnersManage';
export const LOG_MANAGEMENT_VIEW = 'logManagementView';
export const LOG_MANAGEMENT_PODLOGS_VIEW = 'logManagementPodLogsView';
export const DEBUG_DASHBOARD_VIEW = 'debugDashboardView';
export const DEBUG_DASHBOARD_DOWNLOAD = 'debugDashboardDownload';
export const MODEL_MANAGEMENT_VIEW_BROWSE = 'modelManagementViewBrowse';
export const MODEL_MANAGEMENT_ADD_MODEL = 'modelManagementAddModel';
export const MODEL_MANAGEMENT_DELETE_MODEL = 'modelManagementDeleteModel';
export const MODEL_MANAGEMENT_EDIT_MODEL = 'modelManagementEditModel';
export const MODEL_MANAGEMENT_MAP_TO_LOCATION = 'modelManagementMapToLocation';
export const PLANS_VIEW_CURRENT_PLAN = 'plansViewCurrentPlan';
export const PLANS_CHANGE_PLAN = 'plansChangePlan';
export const HELP_VIEW = 'helpView';
export const ACCESS_SUPER_ADMIN = 'accessSuperAdmin';

export const WOCAM_ACCESS_LIVEVIEW = 'woCamAccessLiveView';
export const WOCAM_VIEW_AND_TAG_RECORDINGS = 'woCamViewAndTagRecordings';
export const ACCESS_DRIVETHRU = 'accessDriveThru';
export const ACCESS_ANALYTICS = 'accessAnalytics';
export const ACCESS_PERSON_SUITE = 'accessPersonSuite';
export const ACCESS_POS_ANALYTICS = 'accessPosAnalytics';

export const IS_INTERNAL_PARTNER = 'isInternalPartner';
