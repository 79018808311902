import * as React from 'react';

interface LogManagementIconProps {
  size?: string | number;
  color?: string;
}

function LogManagementIcon({ color, size }: LogManagementIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 1.28613H18V3.28613H5V1.28613ZM0 0.786133H3V3.78613H0V0.786133ZM0 7.78613H3V10.7861H0V7.78613ZM0 14.7861H3V17.7861H0V14.7861ZM5 8.28613H18V10.2861H5V8.28613ZM5 15.2861H18V17.2861H5V15.2861Z"
        fill={color}
      />
    </svg>
  );
}

LogManagementIcon.defaultProps = {
  size: '18',
  color: 'currentColor',
};

export default LogManagementIcon;
