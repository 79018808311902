import Logger from 'Common/Helpers/logger';
import accessSuperAdmin from '../Requests/accessSuperAdmin';

async function accessSuperAdminUseCase() {
  try {
    const data = await accessSuperAdmin();

    if (data) {
      window.open(data, '_blank');
    }
  } catch (ex) {
    const error = ex as Error;
    Logger('Error occurred while accessing super admin account -', error);
  }
}

export default accessSuperAdminUseCase;
