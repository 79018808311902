import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import './styles.scss';
import PageNotFound from 'Common/Pages/PageNotFound';
import NotificationContainer from 'Common/Components/Notification';
import useAuthSync from 'Common/Hooks/useAuthSync';
import GlobalLoader from './Components/GlobalLoader';
import AppLayout from './Components/AppLayout';
import { authRoutes, unAuthRoutes } from './routes';

function Portal() {
  useAuthSync();
  const getAuthRoutes = () => (
    <AppLayout>
      <Routes>
        {authRoutes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AppLayout>
  );

  return (
    <div className="wobot-partner-portal">
      <NotificationContainer />
      <GlobalLoader />
      <Routes>
        {unAuthRoutes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        <Route path="*" element={getAuthRoutes()} />
      </Routes>
    </div>
  );
}

export default Portal;
