import React, { CSSProperties } from 'react';

interface SvgIconProps {
  color?: CSSProperties['color'];
  size?: number | string;
}

function RaiseTicketIcon({ color, size }: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 5.23828V1.11328C0.5 0.914369 0.579018 0.723603 0.71967 0.582951C0.860322 0.442299 1.05109 0.363281 1.25 0.363281H14.75C14.9489 0.363281 15.1397 0.442299 15.2803 0.582951C15.421 0.723603 15.5 0.914369 15.5 1.11328V5.23828C15.0027 5.23828 14.5258 5.43583 14.1742 5.78746C13.8225 6.13909 13.625 6.616 13.625 7.11328C13.625 7.61056 13.8225 8.08748 14.1742 8.43911C14.5258 8.79074 15.0027 8.98828 15.5 8.98828V13.1133C15.5 13.3122 15.421 13.503 15.2803 13.6436C15.1397 13.7843 14.9489 13.8633 14.75 13.8633H1.25C1.05109 13.8633 0.860322 13.7843 0.71967 13.6436C0.579018 13.503 0.5 13.3122 0.5 13.1133V8.98828C0.997281 8.98828 1.47419 8.79074 1.82583 8.43911C2.17746 8.08748 2.375 7.61056 2.375 7.11328C2.375 6.616 2.17746 6.13909 1.82583 5.78746C1.47419 5.43583 0.997281 5.23828 0.5 5.23828ZM2 4.08928C2.56341 4.3685 3.0376 4.79957 3.36913 5.33388C3.70065 5.86818 3.87632 6.48448 3.87632 7.11328C3.87632 7.74208 3.70065 8.35838 3.36913 8.89269C3.0376 9.42699 2.56341 9.85806 2 10.1373V12.3633H14V10.1373C13.4366 9.85806 12.9624 9.42699 12.6309 8.89269C12.2993 8.35838 12.1237 7.74208 12.1237 7.11328C12.1237 6.48448 12.2993 5.86818 12.6309 5.33388C12.9624 4.79957 13.4366 4.3685 14 4.08928V1.86328H2V4.08928ZM5.75 4.86328H10.25V6.36328H5.75V4.86328ZM5.75 7.86328H10.25V9.36328H5.75V7.86328Z"
        fill={color}
      />
    </svg>
  );
}

RaiseTicketIcon.defaultProps = {
  color: 'currentColor',
  size: 16,
};

export default RaiseTicketIcon;
