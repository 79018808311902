import React from 'react';
import Skeleton from 'Common/Skeleton';
import styles from './skeleton.module.scss';

function TasksCraouselSkeleton() {
  return (
    <div className={styles.tasksSkeleton}>
      <div className={styles.taskCarouselSkeleton}>
        <Skeleton height={20} className={styles.title} />
        <Skeleton count={2} height={10} className={styles.details} />
      </div>
      <div className={styles.taskCarouselSkeleton}>
        <Skeleton height={20} className={styles.title} />
        <Skeleton count={2} height={10} className={styles.details} />
      </div>
    </div>
  );
}

export default TasksCraouselSkeleton;
