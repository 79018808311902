import * as React from 'react';
import styles from './textarea.module.scss';

interface TextareaProps {
  id?: string;
  name?: string;
  className?: string;
  textareaClassName?: string;
  label?: string | number;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  value?: string | number;
  error?: boolean;
  message?: string;
  placeholder?: string;
  required?: boolean;
  autoComplete?: boolean;
  minLength?: number;
  maxLength?: number;
  autoFocus?: boolean;
  disabled?: boolean;
  highlightErrorTextarea?: boolean;
  helperText?: JSX.Element;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  rows?: number;
  cols?: number;
}

function Textarea({
  className,
  textareaClassName,
  name,
  label,
  id,
  onChange,
  value,
  error,
  message,
  placeholder,
  required,
  autoComplete,
  minLength,
  maxLength,
  autoFocus,
  disabled,
  highlightErrorTextarea,
  helperText,
  onFocus,
  onBlur,
  rows,
  cols,
}: TextareaProps) {
  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);

  React.useEffect(() => {
    if (autoFocus && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [textareaRef, autoFocus]);

  return (
    <div className={`wo-form-group form-group ${styles.formGroup} ${className}`}>
      {label && (
        <label htmlFor={id || name}>
          {label}
          {/* {required && <sup className={styles.required}>*</sup>} */}
        </label>
      )}

      <div className={styles.inputContainer}>
        <textarea
          id={id || name}
          name={name}
          value={value}
          placeholder={placeholder}
          className={`form-control ${textareaClassName} ${
            error && highlightErrorTextarea ? 'error-red' : ''
          }`}
          onChange={onChange}
          autoComplete={autoComplete ? 'on' : 'off'}
          minLength={minLength}
          maxLength={maxLength}
          ref={textareaRef}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          rows={rows}
          cols={cols}
        />
      </div>
      {error && (
        <span className={`input-hint-msg ${error ? 'error-msg' : ''} ${message ? 'show' : 'hide'}`}>
          {message}
        </span>
      )}
      {helperText}
    </div>
  );
}

Textarea.defaultProps = {
  id: '',
  name: '',
  className: '',
  textareaClassName: '',
  label: '',
  onChange: undefined,
  value: '',
  error: false,
  message: '',
  placeholder: '',
  required: false,
  autoComplete: true,
  minLength: 0,
  maxLength: undefined,
  autoFocus: false,
  disabled: false,
  highlightErrorTextarea: true,
  helperText: undefined,
  onFocus: undefined,
  onBlur: undefined,
  rows: undefined,
  cols: undefined,
};

export default Textarea;
