import {
  ForgotPasswordPayload,
  ForgotPasswordPayloadAction,
  SET_FORGOT_PASSWORD_STATE,
} from './Types';

const initialState: ForgotPasswordPayload = {
  email: '',
  id: '',
};

const forgotPasswordStateReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: ForgotPasswordPayloadAction,
): ForgotPasswordPayload => {
  switch (action.type) {
    case SET_FORGOT_PASSWORD_STATE:
      return { ...state, email: action.payload.email, id: action.payload.id };
    default:
      return state;
  }
};

export default forgotPasswordStateReducer;
