import * as React from 'react';
import styles from './text-input.module.scss';

interface TextInputProps {
  id?: string;
  name?: string;
  className?: string;
  inputClassName?: string;
  label?: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string | number;
  type?: HTMLInputElement['type'];
  error?: boolean;
  message?: string;
  placeholder?: string;
  required?: boolean;
  autoComplete?: boolean;
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  minLength?: number;
  maxLength?: number;
  autoFocus?: boolean;
  disabled?: boolean;
  highlightErrorInput?: boolean;
  helperText?: JSX.Element;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  leftLabelText?: string;
  onCopy?: React.ClipboardEventHandler<HTMLInputElement>;
  onPaste?: React.ClipboardEventHandler<HTMLInputElement>;
  pattern?: string;
  title?: string;
}

function TextInput({
  className,
  inputClassName,
  name,
  label,
  id,
  onChange,
  type,
  value,
  error,
  message,
  placeholder,
  required,
  autoComplete,
  startAdornment,
  endAdornment,
  minLength,
  maxLength,
  autoFocus,
  disabled,
  highlightErrorInput,
  helperText,
  onFocus,
  onBlur,
  leftLabelText,
  onCopy,
  onPaste,
  pattern,
  title,
}: TextInputProps) {
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, autoFocus]);

  return (
    <div className={`wo-form-group form-group ${styles.formGroup} ${className}`}>
      <div className={styles.label}>
        {label && (
          <label htmlFor={id || name}>
            {label}
            {/* {required && <sup className={styles.required}>*</sup>} */}
          </label>
        )}
        <span className={styles.leftLabelText}>{leftLabelText}</span>
      </div>

      <div className={styles.inputContainer}>
        <div className={styles.startAdornment}>{startAdornment}</div>
        <input
          id={id || name}
          name={name}
          value={value}
          placeholder={placeholder}
          className={`form-control ${inputClassName} ${disabled ? styles.textInputDisabled : ''} ${
            startAdornment ? styles.inputPaddingStartAdornment : ''
          }  ${endAdornment ? styles.inputPaddingEndAdornment : ''} ${
            error && highlightErrorInput ? 'error-red' : ''
          }`}
          type={type}
          onChange={onChange}
          autoComplete={autoComplete ? 'on' : 'off'}
          minLength={minLength}
          maxLength={maxLength}
          ref={inputRef}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          onCopy={onCopy}
          onPaste={onPaste}
          pattern={pattern}
          title={title}
        />
        <div className={styles.endAdornment}>{endAdornment}</div>
      </div>
      {error && message && (
        <span className={`input-hint-msg ${error ? 'error-msg' : ''} ${message ? 'show' : 'hide'}`}>
          {message}
        </span>
      )}
      {helperText}
    </div>
  );
}

TextInput.defaultProps = {
  id: '',
  name: '',
  className: '',
  inputClassName: '',
  label: '',
  onChange: undefined,
  type: 'text',
  value: '',
  error: false,
  message: '',
  placeholder: '',
  required: false,
  autoComplete: true,
  startAdornment: undefined,
  endAdornment: undefined,
  minLength: 0,
  maxLength: undefined,
  autoFocus: false,
  disabled: false,
  highlightErrorInput: true,
  helperText: undefined,
  onFocus: undefined,
  onBlur: undefined,
  leftLabelText: undefined,
  onCopy: undefined,
  onPaste: undefined,
  pattern: undefined,
  title: undefined,
};

export default TextInput;
