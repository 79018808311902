import { ActivePlanAction, ActivePlanState } from '../../Types/activePlan';

export const SET_ACTIVE_PLAN_STATE = 'SET_ACTIVE_PLAN_STATE';
export const CLEAR_ACTIVE_PLAN_STATE = 'CLEAR_ACTIVE_PLAN_STATE';

export type SetActivePlanStateAction = ActivePlanAction<typeof SET_ACTIVE_PLAN_STATE>;
export type ClearActivePlanStateAction = ActivePlanAction<typeof CLEAR_ACTIVE_PLAN_STATE>;

export function setActivePlanStateAction(payload: ActivePlanState) {
  return {
    type: SET_ACTIVE_PLAN_STATE,
    payload,
  };
}

export function clearActivePlanStateAction() {
  return {
    type: CLEAR_ACTIVE_PLAN_STATE,
    payload: undefined,
  };
}

export type ActivePlanStateAction = SetActivePlanStateAction | ClearActivePlanStateAction;
