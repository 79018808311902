/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode, memo } from 'react';
import ReactSelect, {
  ActionMeta,
  GroupBase,
  MenuPlacement,
  PropsValue,
  SingleValue,
} from 'react-select';
import scssStyles from './select.module.scss';
import getCustomStyles from './customStyles';

export interface SelectOption {
  value: string;
  label: string;
  parentLabel?: string;
  parentValue?: string;
}

export interface GroupedSelectOption {
  label: string;
  value: string;
  options: SelectOption[];
}

function NoOptionsMessage(message: string) {
  return <p className={scssStyles.noOptions}>{message}</p>;
}

const formatGroupLabel = (data: GroupBase<SelectOption>) => (
  <div className={scssStyles.groupLabel}>
    <span>{data.label}</span>
  </div>
);

interface SelectProps {
  options: Array<SelectOption | GroupedSelectOption>;
  label?: ReactNode;
  defaultValue?: SelectOption;
  name?: string;
  id?: string;
  value?: PropsValue<SelectOption>;
  handleChange: (
    option: SingleValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>,
    parent?: { label: string; value: string },
  ) => void;
  placeholder?: string;
  error?: boolean;
  message?: string;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  showOptionalLabel?: boolean;
  icon?: JSX.Element;
  menuPortalTarget?: HTMLElement;
  highlightOnError?: boolean;
  styles?: object;
  noOptionsMessage?: string;
  menuPlacement?: MenuPlacement;
}

function Select({
  options,
  defaultValue,
  label,
  name,
  id,
  value,
  handleChange,
  placeholder,
  error,
  message,
  className,
  containerClassName,
  disabled,
  showOptionalLabel,
  icon,
  menuPortalTarget,
  highlightOnError,
  styles,
  noOptionsMessage,
  menuPlacement,
  ...props
}: SelectProps) {
  const customStyles = getCustomStyles(!!icon, highlightOnError && error && message !== undefined);

  const modifiedOptions = options?.map((option) => {
    if ('options' in option) {
      const group = option as GroupedSelectOption;
      return {
        ...group,
        options: group.options?.map((opt) => ({
          ...opt,
          parentLabel: group.label,
          parentValue: group.value,
        })),
      };
    }
    return option;
  });

  return (
    <div className={`${scssStyles.selectContainer} ${containerClassName}`}>
      {label && (
        <label htmlFor={id || name}>
          {label}
          {showOptionalLabel && <span>(optional)</span>}
        </label>
      )}
      <div className={scssStyles.iconWrapper}>{icon}</div>
      <ReactSelect
        className={`${className}`}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
          NoOptionsMessage: () => NoOptionsMessage(noOptionsMessage || 'No options'),
        }}
        defaultValue={defaultValue}
        options={modifiedOptions}
        value={value}
        menuPlacement={menuPlacement}
        styles={{ ...customStyles, ...styles }}
        onChange={(selectedOption, actionMeta) => {
          const parentInfo =
            selectedOption && 'parentLabel' in selectedOption && 'parentValue' in selectedOption
              ? {
                label: (selectedOption as SelectOption).parentLabel!,
                value: (selectedOption as SelectOption).parentValue!,
              }
              : undefined;
          handleChange(selectedOption, actionMeta, parentInfo);
        }}
        menuShouldScrollIntoView={false}
        menuPortalTarget={menuPortalTarget}
        isMulti={false}
        isDisabled={disabled}
        formatGroupLabel={formatGroupLabel}
        {...props}
      />
      {error && message && (
        <span className={`input-hint-msg ${error ? 'error-msg' : ''} ${message ? 'show' : 'hide'}`}>
          {message}
        </span>
      )}
    </div>
  );
}

Select.defaultProps = {
  id: '',
  name: '',
  label: '',
  defaultValue: undefined,
  placeholder: 'Select role...',
  value: undefined,
  error: false,
  message: undefined,
  className: '',
  containerClassName: '',
  disabled: false,
  showOptionalLabel: false,
  icon: undefined,
  menuPortalTarget: null,
  highlightOnError: false,
  styles: {},
  noOptionsMessage: 'No options',
  menuPlacement: 'bottom',
};

export default memo(Select);
