import { combineReducers } from 'redux';
import authReducer from '../../Modules/Authorization/States/reducer';
import globalLoaderReducer from '../../Components/GlobalLoader/Reducers';
import nextRouteReducer from '../States/NextRoute/Reducer';
import resetStoreReducer from '../States/ResetStore/Reducer';
import activePlanReducer from '../../Modules/Company/States/ActivePlan/reducer';
import forgotPasswordStateReducer from '../States/ForgotPassword/Reducer';

const rootReducer = combineReducers({
  resetStore: resetStoreReducer,
  auth: authReducer,
  loader: globalLoaderReducer,
  nextRoute: nextRouteReducer,
  activePlan: activePlanReducer,
  forgotPassword: forgotPasswordStateReducer,
});

export default rootReducer;
