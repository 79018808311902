import * as React from 'react';
import ReactSkeleton, { SkeletonProps, SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function Skeleton(props: SkeletonProps) {
  return (
    <SkeletonTheme baseColor="#eeeeee" highlightColor="#f5f5f5">
      <ReactSkeleton
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </SkeletonTheme>
  );
}

export default Skeleton;
