import { NavigateFunction } from 'react-router-dom';
import GetUserAuthData from '../Requests/GetUserAuthData';
import { setAuthState } from '../States';
import LogoutUseCase from './LogoutUseCase';
import { getAuthToken } from '../../../Helpers/appCookie';

interface GetUserAuthDataUseCaseI {
  navigate: NavigateFunction;
}

async function GetUserAuthDataUseCase({ navigate }: GetUserAuthDataUseCaseI) {
  const isTokenExists = getAuthToken();
  try {
    if (!isTokenExists) return;
    const response = await GetUserAuthData({ showLoader: true });
    const { user, manifest, token, isWobotPartner, wopipeUserRole, accessList, canAssignRoles } =
      response;

    setAuthState({
      user: { ...user, accessList, canAssignRoles },
      manifest,
      authToken: token,
      isWobotPartner,
      wopipeUserRole,
    });
  } catch (ex) {
    LogoutUseCase({ navigate });
  }
}

export default GetUserAuthDataUseCase;
