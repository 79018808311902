import { PaginationParams } from 'Common/Hooks/useMakePaginationRequest';
import Logger from 'Common/Helpers/logger';
import getCompaniesList from '../Requests/getCompaniesList';

const getCompaniesListUseCase = async ({ page, limit, query, sortBy, sort }: PaginationParams) => {
  try {
    const response = await getCompaniesList({
      page: page - 1,
      limit,
      query: query || '',
      sortBy: sortBy || '',
      sort: sort || '',
    });
    return response;
  } catch (error) {
    Logger('Error fetching companies:', error);
    return null;
  }
};

export default getCompaniesListUseCase;
