import { AuthState } from '../Types';
import {
  AuthStateAction, CLEAR_AUTH_STATE, SET_AUTH_STATE,
} from './actions';

const initialState: AuthState = {
  user: null,
  authToken: '',
  manifest: null,
  isWobotPartner: false,
  wopipeUserRole: '',
};

// eslint-disable-next-line default-param-last
const authReducer = (state = initialState, action: AuthStateAction): AuthState => {
  switch (action.type) {
    case SET_AUTH_STATE: {
      const {
        user = null,
        authToken = '',
        manifest = null,
        isWobotPartner = false,
        wopipeUserRole = 'Member',
      } = action.payload || {};
      return {
        ...state,
        user,
        authToken,
        manifest,
        isWobotPartner,
        wopipeUserRole,
      };
    }
    case CLEAR_AUTH_STATE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default authReducer;
