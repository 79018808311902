import { METHODS } from 'Common/Requests/request';
import apiCall from '../../../Helpers/apiCall';

function accessSuperAdmin() {
  return apiCall<string>(
    {
      url: '/v1/company/superadmin/access',
      method: METHODS.POST,
    },
    {
      notifyErrorMessage: true,
      errorMessage: 'Failed to access super admin account',
    },
  );
}

export default accessSuperAdmin;
