import { METHODS } from 'Common/Requests/request';
import apiCall from '../../../Helpers/apiCall';
import { LogoutResponse } from '../Types';

async function LogoutRequest({ showSuccessMessage }: { showSuccessMessage?: boolean }) {
  return apiCall<LogoutResponse>(
    {
      url: '/v1/logout',
      method: METHODS.POST,
    },
    {
      showLoader: showSuccessMessage,
      loaderLabel: 'Logging Out...',
      notifySuccess: showSuccessMessage,
      successMessage: 'Successfully logged out',
      notifyErrorMessage: true,
      errorMessage: 'Failed to logout',
    },
  );
}

export default LogoutRequest;
