import { METHODS } from 'Common/Requests/request';
import { IRaiseTicketForm, RaiseATicketResponse } from '../Types';
import apiCall from '../../../../Helpers/apiCall';

function raiseATicket(formData: IRaiseTicketForm) {
  return apiCall<RaiseATicketResponse>(
    {
      url: `/v1/customer/raiseQuery/${process.env.REACT_APP_CLICK_UP_ID}`,
      method: METHODS.POST,
      body: formData,
    },
    {
      notifyErrorMessage: true,
      notifySuccess: false,
      showLoader: false,
      loaderLabel: 'Raising a support ticket',
    },
  );
}

export default raiseATicket;
