import React, { CSSProperties } from 'react';

interface SvgIconProps {
  color?: CSSProperties['color'];
  width?: number | string;
  height?: number | string;
}

function UserSecureIcon({ color, width, height }: SvgIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.522047 1.88431L6 0.666992L11.4779 1.88431C11.783 1.9521 12 2.22264 12 2.53511V9.19293C12 10.5303 11.3316 11.7793 10.2188 12.5211L6 15.3337L1.7812 12.5211C0.668407 11.7793 0 10.5303 0 9.19293V2.53511C0 2.22264 0.21702 1.9521 0.522047 1.88431ZM1.33333 3.06989V9.19293C1.33333 10.0845 1.77893 10.9171 2.5208 11.4117L6 13.7312L9.4792 11.4117C10.2211 10.9171 10.6667 10.0845 10.6667 9.19293V3.06989L6 2.03285L1.33333 3.06989ZM6 7.33366C5.07953 7.33366 4.33333 6.58747 4.33333 5.66699C4.33333 4.74652 5.07953 4.00033 6 4.00033C6.92047 4.00033 7.66667 4.74652 7.66667 5.66699C7.66667 6.58747 6.92047 7.33366 6 7.33366ZM3.01831 10.667C3.18413 9.16699 4.45581 8.00033 6 8.00033C7.5442 8.00033 8.81587 9.16699 8.98167 10.667H3.01831Z"
        fill={color}
      />
    </svg>
  );
}

UserSecureIcon.defaultProps = {
  color: 'currentColor',
  width: 12,
  height: 16,
};

export default UserSecureIcon;
