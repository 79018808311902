import React, { CSSProperties } from 'react';

interface SvgIconProps {
  color?: CSSProperties['color'];
  height?: number | string;
  width?: number | string;
}

function DevicesIcon({ color, width, height }: SvgIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75643 2.6831C3.80857 2.26607 4.16307 1.95312 4.58333 1.95312H15.4167C15.8369 1.95312 16.1914 2.26607 16.2436 2.6831L17.4936 12.6831C17.4978 12.7174 17.5 12.7519 17.5 12.7865V17.7865C17.5 18.2467 17.1269 18.6198 16.6667 18.6198H3.33333C2.8731 18.6198 2.5 18.2467 2.5 17.7865V12.7865C2.5 12.7519 2.50215 12.7174 2.50643 12.6831L3.75643 2.6831ZM5.31898 3.61979L4.27732 11.9531H15.7227L14.681 3.61979H5.31898ZM15.8333 13.6198H4.16667V16.9531H15.8333V13.6198ZM12.5 14.4531H14.1667V16.1198H12.5V14.4531ZM10.8333 14.4531H9.16667V16.1198H10.8333V14.4531Z"
        fill={color}
      />
    </svg>
  );
}

DevicesIcon.defaultProps = {
  color: 'currentColor',
  width: 20,
  height: 21,
};

export default DevicesIcon;
