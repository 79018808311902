import * as React from 'react';

interface SvgIconProps {
  size?: string | number;
  color?: string;
}

function PartnerIcon({ color, size }: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.88409 1.27828C9.70775 0.729159 10.7778 0.717776 11.613 1.24924L15.0757 3.45275H16.4997C16.9599 3.45275 17.333 3.82585 17.333 4.28608V11.7861C17.333 12.2463 16.9599 12.6194 16.4997 12.6194H15.2333C15.2828 13.4088 14.9109 14.2113 14.1628 14.6602L9.91825 17.207C9.38242 17.5284 8.71725 17.5209 8.19267 17.2006C7.60909 17.7167 6.71075 17.7736 6.05904 17.2615L1.77507 13.8955C1.1364 13.3937 0.974887 12.5364 1.30466 11.8567C0.901904 11.5409 0.666504 11.0573 0.666504 10.5452V4.28613C0.666504 3.8259 1.0396 3.4528 1.49984 3.4528H5.62228L8.88409 1.27828ZM2.47704 10.6571L3.05373 10.1526C4.0789 9.25547 5.63352 9.3443 6.54985 10.3523L8.80167 12.8293C9.52625 13.6264 9.65842 14.7974 9.13142 15.7353L13.3053 13.2311C13.5398 13.0904 13.6246 12.8091 13.5372 12.5722L9.84792 7.40739C9.64067 7.11727 9.27184 6.99112 8.93034 7.09357L6.8593 7.71488C5.97838 7.97913 5.02349 7.73838 4.37316 7.08809L4.12919 6.84412C3.65585 6.37078 3.53344 5.69712 3.73263 5.11947H2.33317V10.5452L2.47704 10.6571ZM10.7183 2.65535C10.4398 2.47819 10.0832 2.48198 9.80859 2.66503L5.3077 5.66561L5.55167 5.90958C5.76845 6.12636 6.08675 6.2066 6.38039 6.11851L8.45142 5.49719C9.47592 5.18984 10.5825 5.56829 11.2042 6.43866L14.4285 10.9527H15.6663V5.11942H15.0757C14.7587 5.11942 14.4483 5.02903 14.1808 4.85886L10.7183 2.65535ZM4.15124 11.4068L2.80478 12.585L7.08875 15.951L7.67534 14.9244C7.8545 14.6109 7.81134 14.2176 7.56842 13.9504L5.31661 11.4734C5.01117 11.1374 4.49296 11.1078 4.15124 11.4068Z"
        fill={color}
      />
    </svg>
  );
}

PartnerIcon.defaultProps = {
  size: '18',
  color: 'currentColor',
};

export default PartnerIcon;
