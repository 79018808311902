import React, { CSSProperties } from 'react';
import ReactAvatar from 'react-avatar';

interface AvatarProps {
  name: string;
  src: string;
  size?: string;
  className?: string;
  color: CSSProperties['color'];
  round?: string;
  textSizeRatio?: number;
}

function Avatar({ name, src, size, className, color, round, textSizeRatio }: AvatarProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <ReactAvatar
      name={name}
      alt={name}
      src={src}
      size={size}
      className={className}
      color={color}
      round={round}
      textSizeRatio={textSizeRatio}
    />
  );
}

Avatar.defaultProps = {
  round: '50%',
  size: '40',
  textSizeRatio: 2,
  className: '',
};

export default Avatar;
