export interface ForgotPasswordPayload {
  id: string;
  email: string;
}

export const SET_FORGOT_PASSWORD_STATE = 'SET_FORGOT_PASSWORD_STATE';

export interface ForgotPasswordPayloadAction {
  type: typeof SET_FORGOT_PASSWORD_STATE;
  payload: ForgotPasswordPayload;
}
