export const SHOW_GLOBAL_LOADER = 'SHOW_GLOBAL_LOADER';

export const HIDE_GLOBAL_LOADER = 'HIDE_GLOBAL_LOADER';

export interface LoaderState {
  show?: boolean;
  label?: string;
}

export interface GlobalLoaderAction {
  type: typeof SHOW_GLOBAL_LOADER | typeof HIDE_GLOBAL_LOADER;
  payload: {
    label?: string;
  };
}
