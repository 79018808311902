import Logger from 'Common/Helpers/logger';
import { NavigateFunction } from 'react-router-dom';
import LogoutRequest from '../Requests/Logout';
import { ROUTES } from '../../../Constants/routes';
import { removeDeviceInfo } from '../../../Helpers/deviceInfo';

interface LogoutUseCaseRequest {
  navigate: NavigateFunction;
  persist?: boolean;
  redirectToBaseUrl?: boolean;
  showSuccessMessage?: boolean;
}

async function LogoutUseCase({
  navigate,
  redirectToBaseUrl = true,
  showSuccessMessage = true,
}: LogoutUseCaseRequest) {
  try {
    const res = await LogoutRequest({ showSuccessMessage });
    if (res) {
      redirectToBaseUrl && navigate(ROUTES.LOGIN);
      removeDeviceInfo();
      const channel = new BroadcastChannel('auth');
      channel.postMessage('logout');
      channel.close();
    }
  } catch (ex) {
    Logger('ex', ex);
  }
}

export default LogoutUseCase;
