import * as React from 'react';
import { Link } from 'react-router-dom';
import styles from './page-not-found.module.scss';

interface PageNotFoundProps {
  showLogo?: boolean;
}

function PageNotFound({ showLogo }: PageNotFoundProps) {
  return (
    <div className={styles.container}>
      <div className="row">
        <div className="col-lg-12 align-items-center">
          <div className="justify-content-center">
            {showLogo && (
              <div className={styles.logo}>
                <img
                  className={`mx-auto d-block img-fluid ${styles.wobotTextStyle}`}
                  src="/assets/wobot-logo.png"
                  alt="logo"
                />
              </div>
            )}
            <div className={styles.cherryImg}>
              <img
                className={`mx-auto d-block img-fluid ${styles.imgStyle}`}
                src="/assets/404ErrorPage.svg"
                alt="logo"
              />
            </div>
            <div className="content-404 mxw-350">
              <h3 className="fs-40 fw-700">Oops!</h3>
              <p className="fs-18 fw-500 heading-primary mt-60">
                The page you&#39;re looking does not exist
              </p>
              <p className="mb-0 fs-14 fw-400 heading-primary mt-60">
                Don&#39;t panic! Click on the button below and go back to the home screen
              </p>
              <Link to="/" className="btn btn-sm btn-primary mt-4 mb-4">
                Back to Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PageNotFound.defaultProps = {
  showLogo: false,
};

export default PageNotFound;
