import * as React from 'react';
import styles from './mobile-maintenance.module.scss';

function MobileMaintenance() {
  return (
    <main className={styles.maintenanceContainer}>
      <img src="/assets/wobot-logo_icon-blue.svg" alt="logo" className={styles.logo} />
      <img src="/images/conent.svg" alt="" />
      <img src="/images/fade1.svg" alt="" />
      <div className={styles.textContent}>
        <h3>Err!</h3>
        <p>
          We are currently unavailable on the mobile browser. Please switch to the desktop site and
          stream live events seamlessly.
        </p>
      </div>
    </main>
  );
}

export default MobileMaintenance;
