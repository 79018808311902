import {
  SET_NEXT_ROUTE,
  SetNextRouteAction,
  UNSET_NEXT_ROUTE,
  UnsetNextRouteAction,
} from './Types';

export const setNextRouteAction = (nextRoute: string): SetNextRouteAction => ({
  type: SET_NEXT_ROUTE,
  payload: nextRoute,
});

export const unsetNextRouteAction = (): UnsetNextRouteAction => ({
  type: UNSET_NEXT_ROUTE,
});
