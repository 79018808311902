import React from 'react';
import Skeleton from 'Common/Skeleton';
import styles from './skeleton.module.scss';
import TasksCraouselSkeleton from './TasksCarouselSkeleton';
import DeviceListingSkeleton from './DeviceListingSkeleton';

function DeviceManagementLoadingSkeleton() {
  return (
    <div>
      <div className={styles.selectWrapper}>
        <Skeleton width={200} height={40} />
        <Skeleton width={200} height={40} />
        <Skeleton width={200} height={40} />
      </div>
      <div className={styles.skeletonWrapper}>
        <DeviceListingSkeleton />
        <div className={styles.rightSkeleton}>
          <div className={styles.detailsWrapperSkeleton}>
            <div className={styles.detailsSkeleton}>
              <Skeleton height={40} className={styles.title} />
              <Skeleton count={4} height={20} className={styles.details} />
            </div>
            <div className={styles.detailsSkeleton}>
              <Skeleton height={40} className={styles.title} />
              <Skeleton count={4} height={20} className={styles.details} />
            </div>
          </div>
          <Skeleton width={150} height={40} />
          <TasksCraouselSkeleton />
          <Skeleton width={150} height={40} />
          <div className={styles.servicesSkeleton}>
            <Skeleton height={80} count={2} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceManagementLoadingSkeleton;
