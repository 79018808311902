/* eslint-disable import/no-relative-packages */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../Portal/Constants/routes';
import { unsetNextRoute } from '../../Portal/Store/States/NextRoute';
import store from '../../Portal/Store';

const useAuthSync = () => {
  const navigate = useNavigate();
  const redirectRoute = store.getState()?.nextRoute?.url;

  useEffect(() => {
    const channel = new BroadcastChannel('auth');

    const handleAuthChange = (event: MessageEvent) => {
      if (event.data === 'logout') {
        navigate(ROUTES.LOGIN);
      } else if (event.data === 'login') {
        navigate(redirectRoute || ROUTES.BASE);
        unsetNextRoute();
      }
    };

    channel.onmessage = handleAuthChange;

    return () => {
      channel.close();
    };
  }, [navigate, redirectRoute]);

  return null;
};

export default useAuthSync;
