import { v4 as uuid } from 'uuid';
import { deviceDetect, osName } from 'react-device-detect';
// import { clearAuthState } from '../Modules/Authorization/States';
// import { setNextRoute } from '../Store/States/NextRoute';
// import { DEFAULT_ROUTE } from '../Constants/routes';
import { deleteAuthTokenCookie } from './appCookie';

interface DeviceInfo {
  timeZone: string;
  deviceType: string;
  deviceId: string;
  name: string;
}

const getDeviceInfoKey = () => 'deviceInfo';

const deviceInfoKey = getDeviceInfoKey();

const getSavedDeviceInfo = () => localStorage.getItem(deviceInfoKey);

export const removeDeviceInfo = () => {
  deleteAuthTokenCookie();
  localStorage.removeItem(deviceInfoKey);
  // clearAuthState();
  // setNextRoute(DEFAULT_ROUTE);
};

export const createDeviceInfo = (): DeviceInfo => {
  const { model } = deviceDetect('');

  return {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    deviceType: osName,
    deviceId: uuid(),
    name: `${model || osName}`,
  };
};

export const saveDeviceInfo = (deviceInfo: DeviceInfo) => {
  localStorage.setItem(deviceInfoKey, JSON.stringify(deviceInfo));
};

export const getDeviceInfo = (): DeviceInfo => {
  const deviceInfo = getSavedDeviceInfo();

  if (deviceInfo) {
    return JSON.parse(deviceInfo);
  }

  const newDeviceInfo = createDeviceInfo();

  saveDeviceInfo(newDeviceInfo);

  return newDeviceInfo;
};

export const staticSecretKey = process.env.REACT_APP_SECRET || 'PARTNER@WOBOT.LOCAL';
