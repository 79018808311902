import {
  HIDE_GLOBAL_LOADER, SHOW_GLOBAL_LOADER, GlobalLoaderAction, LoaderState,
} from '../Types';

const initialState: LoaderState = {
  show: false,
  label: 'Loading...',
};

function globalLoaderReducer(
  // eslint-disable-next-line default-param-last
  state: LoaderState = initialState,
  action: GlobalLoaderAction,
): LoaderState {
  switch (action.type) {
    case SHOW_GLOBAL_LOADER: {
      const { label } = action.payload;
      return {
        ...state,
        show: true,
        label: typeof label === 'undefined' ? initialState.label : label,
      };
    }

    case HIDE_GLOBAL_LOADER: {
      return {
        ...state,
        show: false,
        label: initialState.label,
      };
    }

    default:
      return state;
  }
}

export default globalLoaderReducer;
