import React, { MouseEventHandler } from 'react';
import styles from './model-title.module.scss';
import { CustomComponentProps } from '../../../Types/component';
import ModalTitleHeading, { ModalTitleHeadingProps } from '../ModalTitleHeading';
import Button from '../../Button';
import CloseIcon from '../../Icons/CloseIcon';

interface ModalTitleProps extends CustomComponentProps, ModalTitleHeadingProps {
  onClose?: MouseEventHandler<HTMLButtonElement>;
}

function ModalTitle({
  children = null,
  className = '',
  title = '',
  description = '',
  onClose,
}: ModalTitleProps) {
  const closeBtn =
    typeof onClose === 'function' ? (
      <Button variant="text" className={`${styles.modalCloseBtn}`} onClick={onClose}>
        <CloseIcon size={12} />
      </Button>
    ) : null;

  return (
    <div className={`wo-modal-title ${styles.modalTitle} ${className}`}>
      {title || description ? <ModalTitleHeading title={title} description={description} /> : null}
      {children}
      {closeBtn}
    </div>
  );
}

ModalTitle.defaultProps = {
  onClose: undefined,
};

export default ModalTitle;
