const COLORS = {
  PRIMARY: '#3766E8',
  GREEN_SUCCESS: '#029262',
  YELLOW_WARNING: '#ffc107',
  RED_DANGER: '#dc3545',
  TEXT_OTHER: '#7e7e7e',
  GRAY: '#545454',
  SELECT_OPTION_BG: '#EBECF0',
  TEXT_INPUT: '#495057',
  BLACK: '#000000',
  WHITE: '#ffffff',
  TEXT_TERTIARY: '#a0a0a0',
  MUTED_BG: '#f5f5f7',
  GRAY_DARK: '#CED4DA',
  LIGHT_COLOR: '#cccccc',
  LIGHT_GRAY: '#f0f0f0',
  PRIMARY_EXTRA_LIGHT: '#3766e81a',
  GRAPH_LINES: '#e0e0e0',
};

export const getPrimaryColor = () => COLORS.PRIMARY;

export default COLORS;
