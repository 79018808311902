import raiseATicket from '../Requests/raiseATicket';
import uploadMediaRequest from '../Requests/uploadMedia';
import { IRaiseTicketForm } from '../Types';

const raiseATicketUseCase = async (formData: IRaiseTicketForm, attachment: Blob | string) => {
  const result = await raiseATicket(formData);
  const taskId = result.id;
  if (taskId && attachment && attachment !== '') {
    await uploadMediaRequest({ attachment, taskId: result.id });
  }
  return result;
};

export default raiseATicketUseCase;
