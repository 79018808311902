import {
  useCallback, useEffect, useState,
} from 'react';

export function useMakeRequest<T>({
  fetcher,
  initialData,
  fetchDataOnMount = true,
}: {
  fetcher: () => Promise<T>;
  initialData?: T;
  fetchDataOnMount?: boolean;
}) {
  const [data, setData] = useState<T | null>(initialData || null);
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [error, setError] = useState<Error | boolean | null>(null);

  const fetchData = useCallback(
    async (refresh?: boolean) => {
      if (refresh) {
        setRefreshing(true);
      } else {
        setLoading(true);
      }

      try {
        const response = await fetcher();
        setData(response);
        setError(null);
      } catch (ex) {
        const er = ex as Error;
        setError(er);
      } finally {
        setLoading(false);
        setRefreshing(false);
      }
    },
    [fetcher],
  );

  const refreshData = useCallback(() => {
    fetchData(true);
  }, [fetchData]);

  useEffect(() => {
    if (!fetchDataOnMount) {
      return;
    }

    fetchData();
  }, [fetchDataOnMount, fetchData]);

  return {
    loading,
    error,
    data,
    refreshing,
    fetchData,
    setData,
    refreshData,
  };
}

export default useMakeRequest;
