import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import PageNotFound from 'Common/Pages/PageNotFound';
import LazyRoute from 'Common/Components/LazyRoute';
import { ROUTES } from '../../Constants/routes';

const LazyPodStatus = React.lazy(() => import('./Containers/PodStatus'));
const LazyLogManagement = React.lazy(() => import('./Containers/LogManagement'));

function Logs() {
  return (
    <Routes>
      <Route path="/" element={<LazyRoute lazy={LazyLogManagement} />} />
      <Route path={ROUTES.POD_STATUS} element={<LazyRoute lazy={LazyPodStatus} />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default Logs;
