/* eslint-disable no-plusplus */
import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';
import SuccessToastIcon from '../Icons/SuccessToastIcon';
import ErrorToastIcon from '../Icons/ErrorToastIcon';
import WarningToastIcon from '../Icons/WarningToastIcon';

export enum NOTIFICATION_TYPES {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

const showIcon = (type: string) => {
  switch (type) {
    case 'info':
      return <SuccessToastIcon />;
    case 'error':
      return <ErrorToastIcon />;
    case 'warning':
      return <WarningToastIcon />;
    case 'success':
      return <SuccessToastIcon />;

    default:
      return <SuccessToastIcon />;
  }
};

const toSentenceCase = (
  inputString: string,
  includedWords: string[] = ['wobot', 'support'],
): string => {
  if (inputString && inputString.length && typeof inputString === 'string') {
    let capitalizeNext = true;
    let string = inputString.toLowerCase();

    for (let i = 0; i < string.length; i++) {
      if (/[.!?](?=\s)/.test(`${string[i]}${string[i + 1]}`)) {
        capitalizeNext = true;
      } else if (/[a-zA-Z']/i.test(string[i])) {
        if (capitalizeNext || i === 0) {
          string = `${string.slice(0, i)}${string.charAt(i).toUpperCase()}${string.slice(i + 1)}`;
          capitalizeNext = false;
        }

        const wordStartIndex = i;
        let wordEndIndex = i + 1;
        while (wordEndIndex < string.length && /[a-zA-Z']/i.test(string[wordEndIndex])) {
          wordEndIndex++;
        }

        const word = string.substring(wordStartIndex, wordEndIndex);
        const isIncludedWord = includedWords.some(
          (includedWord) => word.toLowerCase() === includedWord.toLowerCase(),
        );

        if (isIncludedWord) {
          if (!/[.!?]$/.test(string.slice(0, wordStartIndex))) {
            string = `${string.slice(0, wordStartIndex)}${word
              .charAt(0)
              .toUpperCase()}${string.slice(wordStartIndex + 1)}`;
          }
        }

        i = wordEndIndex - 1;
      }
    }

    return string;
  }

  return '';
};

export const addNotification = (
  notification: string,
  type: NOTIFICATION_TYPES = NOTIFICATION_TYPES.SUCCESS,
) => {
  toast.dismiss();
  toast(toSentenceCase(notification), {
    type,
    position: 'top-right',
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    hideProgressBar: true,
    icon: showIcon(type),
  });
};

function NotificationContainer() {
  return <ToastContainer />;
}

export default NotificationContainer;
