import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const getCookie = (key: string) => cookies.get(key);

export const setCookie = (key: string, value: any) => {
  cookies.set(key, value, { path: '/' });
  return true;
};

export const deleteCookie = (key: string) => {
  cookies.remove(key);
};

export const deleteAllCookies = () => {
  const allCookies = document.cookie.split(';');

  allCookies.forEach((cookie) => {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  });
};
