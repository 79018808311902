import React, {
  ComponentType, FC, useEffect,
} from 'react';
import {
  Navigate, useLocation, useNavigate,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getAuthToken } from '../Helpers/appCookie';
import { ROUTES } from '../Constants/routes';
import { AppState } from '../Store';
import GetUserAuthDataUseCase from '../Modules/Authorization/UseCases/GetUserAuthDataUseCase';
import { setNextRoute } from '../Store/States/NextRoute';

const selectAuth = (state: AppState) => state.auth;
const selectUser = createSelector([selectAuth], (auth) => auth?.user);

function withAuthentication<P extends object>(Component: ComponentType<P>): FC<P> {
  return function AuthComponent(props) {
    const user = useSelector(selectUser);

    const navigate = useNavigate();
    const location = useLocation();
    const { pathname, search } = location;

    const isTokenExists = !!getAuthToken();

    useEffect(() => {
      if (isTokenExists && !user) {
        GetUserAuthDataUseCase({ navigate });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTokenExists, user]);

    if (!isTokenExists) {
      setNextRoute(`${pathname}${search}`);
      return <Navigate to={ROUTES.LOGIN} />;
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} />;
  };
}

export default withAuthentication;
