import apiCall from '../../../Helpers/apiCall';
import { Companies } from '../Types';

interface GetCompaniesListRequest {
  limit: number;
  page: number;
  query?: string;
  sortBy?: string;
  sort?: string;
}

interface GetCompaniesListResponse {
  companies: Companies;
  hasNextPage: boolean;
  limit: number | null;
  page: number | null;
  total: number | null;
  totalPages: number | null;
}

function getCompaniesList({ page, limit, query, sortBy, sort }: GetCompaniesListRequest) {
  return apiCall<GetCompaniesListResponse>({
    url: '/v1/company/list',
    queryParams: { limit, page, query, sortBy, sort },
  });
}

export default getCompaniesList;
