import {
  RESET_STORE_STATE, ResetStoreState, ResetStoreStateAction,
} from './Types';

const initialState: ResetStoreState = {};

const resetStoreReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: ResetStoreStateAction,
): ResetStoreState => {
  switch (action.type) {
    case RESET_STORE_STATE:
      return {};
    default:
      return state;
  }
};

export default resetStoreReducer;
