import * as React from 'react';
import styles from './model-content.module.scss';
import { CustomComponentProps } from '../../../Types/component';

interface ModalContentProps extends CustomComponentProps {}

function ModalContent({ children, className, id }: ModalContentProps) {
  return (
    <div id={id} className={`wo-modal-content ${styles.modalContent} ${className}`}>
      {children}
    </div>
  );
}

ModalContent.defaultProps = {
  children: null,
  className: '',
};

export default ModalContent;
