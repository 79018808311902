import {
  ContactOption, IRaiseTicketForm, PriortyOption,
} from './Types';

export const ContactOptionsStringMap: Record<string, ContactOption> = {
  'Issue / bug': ContactOption.ISSUE_BUG,
  Query: ContactOption.QUERY,
  'Feature request': ContactOption.FEATURE_REQUEST,
};

export const PriorityOptionsStringMap: Record<string, string> = {
  1: PriortyOption.URGENT,
  2: PriortyOption.HIGH,
  3: PriortyOption.NORMAL,
  4: PriortyOption.LOW,
};

export const contactOptions = [
  {
    label: ContactOption.ISSUE_BUG,
    value: ContactOption.ISSUE_BUG,
  },
  {
    label: ContactOption.QUERY,
    value: ContactOption.QUERY,
  },
  {
    label: ContactOption.FEATURE_REQUEST,
    value: ContactOption.FEATURE_REQUEST,
  },
];

export const priorityOptions = [
  {
    label: PriortyOption.URGENT,
    value: '1',
  },
  {
    label: PriortyOption.HIGH,
    value: '2',
  },
  {
    label: PriortyOption.NORMAL,
    value: '3',
  },
  {
    label: PriortyOption.LOW,
    value: '4',
  },
];

export const intialRequestFormData: IRaiseTicketForm = {
  contactFor: undefined,
  subject: '',
  details: '',
  priority: undefined,
  media: '',
  selectedCompanyId: '',
  selectedCompanyName: '',
  userId: '',
  username: '',
  role: '',
};

export const FIELD_ERROR_MSGS: Record<string, string> = {
  subject: 'Please enter subject',
  details: 'Please enter details',
  contactFor: 'Please select a request type',
  priority: 'Please select a priority',
  company: 'Please select a company',
};

export const getErrorMessage = (key: string): string => FIELD_ERROR_MSGS[key];
