/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import {
  Accept, FileRejection, useDropzone,
} from 'react-dropzone';
import styles from './fileUpload.module.scss';
import { NOTIFICATION_TYPES, addNotification } from '../../Notification';
import Button from '../../Button';
import AddRoundedIcon from '../../Icons/AddRoundedIcon';

interface FileUploadProps {
  id?: string;
  name?: string;
  className?: string;
  inputClassName?: string;
  label?: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string | ArrayBuffer | null;
  error?: boolean;
  message?: string;
  disabled?: boolean;
  helperText?: JSX.Element;
  onUpload: (e: string | Blob) => void;
  accept?: Accept;
  helperLabel?: string;
}

function FileUpload({
  className,
  name,
  label,
  id,
  message,
  onUpload,
  accept,
  helperLabel,
}: FileUploadProps) {
  const [uploadedFile, setUploadedFile] = React.useState<File | null>(null);

  const removeFile = () => {
    setUploadedFile(null);
    onUpload('');
  };

  const onDrop = React.useCallback(
    async (acceptedFiles: Array<File>, fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        addNotification(message || 'Invalid file type.', NOTIFICATION_TYPES.ERROR);
        return;
      }

      const fileSizeInMB = (acceptedFiles[0].size / (1024 * 1024)) as number;

      if (fileSizeInMB > 5) {
        addNotification('File size exceeds the maximum limit of 5 MB', NOTIFICATION_TYPES.ERROR);
        return;
      }

      const file = new FileReader();

      file.onload = () => {
        setUploadedFile(acceptedFiles[0]);
        onUpload(acceptedFiles[0]);
      };

      file.readAsDataURL(acceptedFiles[0]);
    },
    [onUpload, message],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
  });

  return (
    <div className={`wo-form-group form-group ${styles.formGroup} ${className}`}>
      {label && (
        <label htmlFor={id || name}>
          {label}
          <span>{helperLabel || '(optional)'}</span>
        </label>
      )}

      <div className={styles.inputContainer}>
        <input {...getInputProps()} />
        <Button className={styles.uploadBtn} variant="primary" {...getRootProps()}>
          <>
            <AddRoundedIcon size={24} />
            {' '}
            <p>Choose a file</p>
          </>
        </Button>
        {uploadedFile ? (
          <h6>{uploadedFile?.name}</h6>
        ) : (
          <span className={styles.noFileText}>No file chosen</span>
        )}
        {uploadedFile && (
          <small>
            {uploadedFile.size / (1024 * 1024) >= 1
              ? `(${(uploadedFile.size / (1024 * 1024)).toFixed(2)} MB)`
              : `(${(uploadedFile.size / 1024).toFixed(2)} KB)`}
          </small>
        )}
        {uploadedFile && (
          <Button variant="transparent" onClick={removeFile} className={styles.removeBtn}>
            Remove
          </Button>
        )}
      </div>
      <span className="input-hint-msg show">Max file size 5 MB</span>
    </div>
  );
}

FileUpload.defaultProps = {
  id: '',
  name: '',
  className: '',
  inputClassName: '',
  label: '',
  onChange: undefined,
  value: '',
  error: false,
  message: '',
  disabled: false,
  helperText: undefined,
  accept: {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/gif': ['.gif'],
    'application/vnd.ms-excel': ['.xslv'],
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc'],
  },
  helperLabel: '',
};

export default FileUpload;
