// eslint-disable-next-line import/no-relative-packages
import store from '../../Portal/Store';

export const getAccessList = (): string[] => {
  const { auth } = store.getState();
  return auth?.user?.accessList || [];
};

export const authenticateTabs = (tab: { key?: string }): boolean => {
  if (tab.key) return getAccessList().indexOf(tab.key) !== -1;
  return true;
};

export const authenticateViewAndAction =
(action: string): boolean => getAccessList().indexOf(action) !== -1;

export const authenticateRoutes = (routeName: string): boolean => true;

export const authenticateCards = (cardKey?: string): boolean => {
  if (cardKey) return getAccessList().indexOf(cardKey) !== -1;
  return true;
};
