import React, { JSX } from 'react';
import useWindowSize from 'Common/Hooks/useWindowSize';
import Header from '../Header';
import Sidebar from '../Sidebar';
import withAuthentication from '../../HOC/withAuthentication';
import './styles.scss';
import MobileMaintenance from '../MobileMaintenance';

function DesktopAppLayout({ children }: { children: JSX.Element }) {
  return (
    <div className="app-container">
      <Sidebar className="app-sidebar" />
      <main className="content-wrapper">
        <Header className="app-header" />
        <div className="page-container">{children}</div>
      </main>
    </div>
  );
}

function AppLayout({ children }: { children: JSX.Element }) {
  const { width } = useWindowSize();
  const isSmallScreen = width && width < 990;

  return (
    <div>
      {!isSmallScreen ? <DesktopAppLayout>{children}</DesktopAppLayout> : <MobileMaintenance />}
    </div>
  );
}

export default withAuthentication(AppLayout);
