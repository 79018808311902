import { setCookie as setCookieCommon, getCookie as getCookieCommon } from 'Common/Helpers/cookie';

export const setCookie = (key: string, value: any) => setCookieCommon(key, value);
export const getCookie = (key: string) => getCookieCommon(key);

const AUTH_TOKEN_COOKIE_KEY = 'token';

export const getAuthTokenKey = () => AUTH_TOKEN_COOKIE_KEY;
export const setAuthToken = (token: string) => setCookie(getAuthTokenKey(), token);
export const getAuthToken = () => getCookie(getAuthTokenKey());
// export const deleteAuthTokenCookie = () => deleteAllCookies();
export const deleteAuthTokenCookie = () => setAuthToken('');

export const deleteCookies = () => {
  deleteAuthTokenCookie();
};
