import * as React from 'react';
import styles from './model-footer.module.scss';
import { CustomComponentProps } from '../../../Types/component';

interface ModalFooterProps extends CustomComponentProps {
  content?: 'end' | 'start' | 'center';
}

function ModalFooter({ children = null, className = '', content }: ModalFooterProps) {
  return (
    <div
      className={`wo-modal-footer ${styles.modalFooter} ${className} wmf-align-items-${content}`}
    >
      <div className={`wmf-align-self-${content}`}>{children}</div>
    </div>
  );
}

ModalFooter.defaultProps = {
  content: 'end',
};

export default ModalFooter;
